import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import ReactMarkdown from 'react-markdown';
import input from './content/test.md';

function App() {
  const [markdown, setMarkdown] = useState(input);

  console.log(`App()`);

  useEffect(() => {
    console.log(`useEffect()`);
    fetch(input).then(response => response.text()).then(markdown => setMarkdown(markdown));
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
      <div>
        <ReactMarkdown source={markdown} />
      </div>
    </div>
  );
}

export default App;
